<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div>
          <el-input
            class="input"
            v-model="form.name"
            placeholder="请输入角色名称"
          ></el-input>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="loadArticles(1)"
            >查询</el-button
          >

          <el-button type="success" :disabled="loading" @click="addrole()"
            >新增</el-button
          >
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <!-- 数据列表 -->
      <!--
        Table 表格组件
        1、把需要展示的数组列表数据绑定给 table 组件的 data 属性
          注意：你不用去 v-for 遍历，它自己会遍历
        2、设计表格列 el-table-column
          width 可以设定表格列的宽度
          label 可以设定列的标题
          prop  用来设定要渲染的列表项数据字段，只能展示文本

        3、表格列默认只能渲染普通文本，如果需要展示其它内容，例如放个按钮啊、放个图片啊，那就需要自定义表格列模板了：https://element.eleme.cn/#/zh-CN/component/table#zi-ding-yi-lie-mo-ban
       -->
      <el-table
        :data="tabledata"
        stripe
        height="1150"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column type="index" min-width="300" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="角色名"> </el-table-column>
        <el-table-column label="类型">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.status].type">{{
              urgentStatus[scope.row.status].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="设置权限"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="editjuris(scope.row)"
                  circle
                  icon="el-icon-setting"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                content="编辑"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="editrole(scope.row)"
                  circle
                  icon="el-icon-edit"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                content="删除"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="deleterole(scope.row)"
                  circle
                  icon="el-icon-delete"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- /数据列表 -->

      <!-- 列表分页 -->
      <!--
        total 用来设定总数据的条数
        它默认按照 10 条每页计算总页码
        page-size 每页显示条目个数，支持 .sync 修饰符，默认每页 10 条

        90 3 90 / 3 = 30
       -->
      <el-pagination
        layout="prev, pager, next"
        background
        :total="totalCount"
        :page-size="form.pageSize"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>
    <!-- 编辑、添加 -->
    <el-dialog
      class="form-panel"
      :title="panelTitle"
      :visible.sync="showEditPanel"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="800px"
      @closed="CloseFormPanel"
    >
      <el-form
        ref="form-panel"
        :model="formPanelData"
        class="edit-form"
        label-width="110px"
        label-position="right"
        :rules="formPanelOptionsRules"
        @submit.native.prevent
      >
        <el-form-item
          v-for="(item, index) in formPanelOptions"
          :key="index + item.key"
          class="edit-form-item"
          :label="item.label + '：'"
          :prop="item.key"
          size="small"
        >
          <div v-if="item.type === 'text'" class="edit-form-text">
            <el-input
              :id="'edit' + item.key + index"
              v-model="formPanelData[item.key]"
              :type="item.type"
              :placeholder="'请输入' + item.label"
              clearable
              size="small"
              autocomplete="off"
            />
          </div>
          <div v-if="item.type === 'cascade'" class="edit-form-select">
            <el-cascader
              :id="'edit' + item.key + index"
              v-model="formPanelData[item.key]"
              :type="item.type"
              :options="treeData"
              :props="{ checkStrictly: true }"
              :placeholder="'请选择' + item.label"
              clearable
              @change="handleSelectDept"
            />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="info" @click="CloseFormPanel"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitFormData"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 给角色授权资源 -->
    <el-dialog
      class="empower-panel"
      title="权限配置"
      :visible.sync="showSettingPanel"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
      width="850px"
      @closed="closeSettingPanel"
    >
      <el-tree
        ref="treeRef"
        v-if="showSettingPanel"
        node-key="permId"
        :props="defaultProps"
        :data="permissionList"
        :default-checked-keys="permissionChecked"
        show-checkbox
        default-expand-all
      />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="info" @click="closeSettingPanel"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitSettingData"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoleListurl,
  addRoleurl,
  delRoleurl,
  editRoleurl,
  getallpermlist,
  getpermbyrole,
  empowerPermToRole,
} from "@/api/jurisdiction";
export default {
  components: {},

  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      settingData: {},
      permissionTotal: [], // 处理前 获取的权限列表数据
      permissionList: [], // 处理后 能显示的所有权限列表
      permissionChecked: [], // 当前已选权限列表（处理后----有的是半选中状态）
      showSettingPanel: false,
      panelTitle: "",
      showEditPanel: false,
      tabledata: [],
      form: {
        name: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
      },
      formPanelData: {},
      urgentStatus: [
        { status: 0, text: "内部", type: "info" }, // 1
        { status: 1, text: "外部", type: "primary" }, // 1
      ],
      formPanelOptionsRules: {
        name: [{ required: true, message: "请输入职位名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入职位编号", trigger: "blur" }],
        deptId: [
          { required: true, message: "请选择所属部门", trigger: "blur" },
        ],
      },
      formPanelOptions: [
        { type: "text", label: "角色名", key: "name" },
        { type: "text", label: "角色编号", key: "roleKey" },
        { type: "text", label: "备注", key: "remark" },
      ],

      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading
    };
  },
  computed: {},
  watch: {},
  created() {
    this.loadArticles(1);
    this.getallpermtree();
  },
  mounted() {},
  methods: {
    async getallpermtree() {
      try {
        const res = await getallpermlist();
        if (res.data.code === 200) {
          this.permissionTotal = res.data.data;
          this.permissionList = this.translateDataToTree(res.data.data);
          console.log(this.permissionList);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    // 把父子关系的数组转换为树形结构
    translateDataToTree(data) {
      const parentIdList = data.map((item) => item.parentId);
      data.forEach((item) => {
        if (parentIdList.includes(item.permId)) {
          item.children = [];
        }
      });
      const childrenList = data.filter((item) => item.parentId !== 0);
      for (let i = 0; i < childrenList.length; i++) {
        data.forEach((item) => {
          if (item.permId === childrenList[i].parentId) {
            item.children.push(childrenList[i]);
          }
        });
      }
      return data.filter((item) => item.parentId === 0);
    },
    async submitSettingData() {
      // 选中的配置结果
      const fullChecked = this.$refs.treeRef.getCheckedKeys();
      const hafCheckedKeys = this.$refs.treeRef.getHalfCheckedKeys();
      fullChecked.forEach((item, index) => {
        if (typeof item !== "number") {
          fullChecked.splice(index, 1);
        }
      });
      if (fullChecked.length <= 0) {
        this.$message({
          message: "保存失败，未勾选配置信息",
          type: "error",
        });
        return;
      }
      this.settingData.permIds = fullChecked.concat(hafCheckedKeys);

      try {
        const res = await empowerPermToRole(this.settingData);
        if (res.data.code === 200) {
          this.$message({
            message: "权限配置成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showSettingPanel = false;
      }
    },
    editjuris(row) {
      this.showSettingPanel = true;
      this.settingData.roleId = row.roleId;
      this.fetchCurrentPermission(row.roleId);
    },

    // 完成setting panel
    closeSettingPanel() {
      this.permissionChecked = [];
      this.showSettingPanel = false;
    },

    // 获取某个用户已有权限
    async fetchCurrentPermission(roleId) {
      try {
        const res = await getpermbyrole({ roleId: roleId });
        if (res.data.code === 200) {
          const currentPer = res.data.data.map((item) => item.permId);
          const userPermission = res.data.data;
          const userIds = userPermission.map((item) => item.permId);
          const notChecked = this.permissionTotal.filter(
            (item) => !userIds.includes(item.permId)
          );
          let delArr = [];
          notChecked.forEach((item) => {
            if (!userIds.includes(item.permId)) {
              delArr.push(item.permId);
              this.defaultChecked(this.permissionTotal, item.parentId, delArr);
            }
          });

          // setTimeout(() => {
          this.permissionChecked = currentPer.filter(
            (item) => !delArr.includes(item)
          );
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    // 子节点未全选时递归去掉父节点
    defaultChecked(arr, pid, delArr) {
      arr.forEach((item) => {
        if (item.permId === pid) {
          delArr.push(pid);
          this.defaultChecked(arr, item.parentId, delArr);
        }
      });
    },

    // 删除
    deleterole(row) {
      this.$confirm("将删除该数据，是否继续", "警告", {
        type: "warning",
        confirmButtonText: "确定",
        closeOnClickModal: false, // 点击model背景层不完成MessageBox
      }).then(() => {
        delRoleurl({ roleId: row.roleId }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.loadArticles();
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
      });
    },
    editrole(row) {
      this.formPanelData = row;
      this.panelTitle = "角色编辑";
      this.showEditPanel = true;
    },
    addrole() {
      this.panelTitle = "新建角色";
      this.showEditPanel = true;
    },
    CloseFormPanel() {
      this.showEditPanel = false;
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },

    async loadArticles(page = 1) {
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await getRoleListurl(this.form);
        if (res.data.code === 200) {
          this.tabledata = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async submitFormData() {
      try {
        let res;
        if (this.panelTitle === "新建角色") {
          res = await addRoleurl(this.formPanelData);
        } else if (this.panelTitle === "角色编辑") {
          res = await editRoleurl(this.formPanelData);
        }
        if (res.data.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showEditPanel = false;
        this.formPanelData = {};
        this.loadArticles();
      }
    },
  },
};
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
</style>
