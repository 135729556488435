import request from "../utils/requst"

export const getdeptlisturl = params =>{
    return request({
        method:'GET',
        url:'sys/dept/list',
        params,
    })
}

export const gettransformdeptlisturl = params =>{
    return request({
        method:'GET',
        url:'sys/dept/transform/list',
        params,
    })
}
export const adddeptlisturl = data =>{
    return request({
        method:'POST',
        url:'sys/dept/add',
        data,
    })
}

export const deldeptlisturl = data =>{
    return request({
        method:'DELETE',
        url:'sys/dept/del',
        data,
    })
}
export const getpositionlisturl = params =>{
    return request({
        method:'GET',
        url:'sys/post/page',
        params,
    })
}

export const addpositionurl = data =>{
    return request({
        method:'POST',
        url:'sys/post/add',
        data,
    })
}

export const delpositionturl = data =>{
    return request({
        method:'DELETE',
        url:'sys/post/del',
        data,
    })
}
export const editpositionturl = data =>{
    return request({
        method:'PUT',
        url:'sys/post/edit',
        data,
    })
}

export const getRoleListurl = params =>{
    return request({
        method:'GET',
        url:'sys/role/page',
        params,
    })
}

export const addRoleurl = data =>{
    return request({
        method:'POST',
        url:'sys/role/add',
        data,
    })
}

export const delRoleurl = data =>{
    return request({
        method:'DELETE',
        url:'sys/role/del',
        data,
    })
}

export const editRoleurl = data =>{
    return request({
        method:'PUT',
        url:'sys/role/edit',
        data,
    })
}

export const getallpermlist = params =>{
    return request({
        method:'GET',
        url:'sys/perm/list',
        params,
    })
}

export const getpermbyrole = params =>{
    return request({
        method:'GET',
        url:'sys/perm/listByRole',
        params,
    })
}

export const empowerPermToRole = data =>{
    return request({
        method:'POST',
        url:'sys/role/empowerPerm',
        data,
    })
}

export const getuserlist = params =>{
    return request({
        method:'GET',
        url:'sys/user/page',
        params,
    })
}

export const getupostlist = params =>{
    return request({
        method:'GET',
        url:'sys/post/list',
        params,
    })
}

export const getrolelistUrl = params =>{
    return request({
        method:'GET',
        url:'sys/role/list',
        params,
    })
}

export const adduserurl = data =>{
    return request({
        method:'POST',
        url:'sys/user/add',
        data,
    })
}

export const edituserurl = data =>{
    return request({
        method:'PUT',
        url:'sys/user/edit',
        data,
    })
}

export const deluserurl = data =>{
    return request({
        method:'DELETE',
        url:'sys/user/del',
        data,
    })
}

export const resetpadurl = data =>{
    return request({
        method:'PUT',
        url:'sys/user/password/reset',
        data,
    })
}

export const getBusinessCustomerAccountList = params =>{
    return request({
        method:'GET',
        url:'procedure/business-customer-work-order/getBusinessCustomerAccountList',
        params,
    })
}

export const getAccountDetail = params =>{
    return request({
        method:'GET',
        url:'procedure/business-customer-work-order/getAccountDetail',
        params,
    })
}

export const editTobAccount = data =>{
    return request({
        method:'POST',
        url:'procedure/business-customer-work-order/editTobAccount',
        data,
    })
}
export const addTobAccount = data =>{
    return request({
        method:'POST',
        url:'procedure/business-customer-work-order/addTobAccount',
        data,
    })
}

export const deleteBusinessAccount = data =>{
    return request({
        method:'POST',
        url:'procedure/business-customer-work-order/deleteBusinessAccount',
        data,
    })
}









